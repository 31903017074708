import Card from "./customCard";
import { getDatabase, ref, get } from "firebase/database";
import React, { useState, useEffect } from "react";

const UnpaidReservationsList = () => {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const db = getDatabase();

  const fetchUnpaidReservations = async () => {
    setLoading(true);
    try {
      // First, get all reservation IDs and their dates
      const indexDateRef = ref(db, "restaurant/indexDate");
      const indexDateSnapshot = await get(indexDateRef);

      if (!indexDateSnapshot.exists()) {
        setReservations([]);
        return;
      }

      const reservationDates = indexDateSnapshot.val();
      const unpaidReservations = [];

      // For each reservation ID, fetch its full data
      for (const [reservationId, dateString] of Object.entries(
        reservationDates
      )) {
        const [year, month, day] = dateString.split("/");
        const reservationRef = ref(
          db,
          `restaurant/${year}/${month}/${day}/reservations/${reservationId}`
        );
        const reservationSnapshot = await get(reservationRef);

        if (reservationSnapshot.exists()) {
          const reservationData = reservationSnapshot.val();
          // Check if the reservation has no pago field or if it's empty
          if (!reservationData.pago) {
            unpaidReservations.push({
              id: reservationId,
              date: dateString,
              ...reservationData,
            });
          }
        }
      }

      setReservations(unpaidReservations);
    } catch (err) {
      console.error("Error fetching unpaid reservations:", err);
      setError("Could not fetch reservations. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUnpaidReservations();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-gray-600">Loading reservations...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">
          Unpaid Reservations
        </h1>
        <button
          onClick={fetchUnpaidReservations}
          className="px-4 py-2 bg-[#800000] hover:bg-[#600000] text-white font-bold rounded-full"
        >
          Refresh List
        </button>
      </div>

      {reservations.length === 0 ? (
        <div className="text-center text-gray-600 py-8">
          No unpaid reservations found
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {reservations.map((reservation) => (
            <div key={reservation.id} className="flex flex-col">
              <div className="bg-gray-100 px-4 py-2 rounded-t-lg">
                <span className="font-semibold">Date: </span>
                {reservation.date}
              </div>
              <Card data={reservation} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UnpaidReservationsList;
