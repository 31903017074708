import Card from "./customCard";
import { getDatabase, ref, get } from "firebase/database";
import React, { useState, useEffect } from "react";

const PaidReservationsList = () => {
  const [selectedDate, setSelectedDate] = useState({
    year: "",
    month: "",
    day: "",
  });
  const [reservations, setReservations] = useState([]);
  const [menuTotals, setMenuTotals] = useState({
    menu5Steps: 0,
    menu3Steps: 0,
    menuInfantil: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const db = getDatabase();

  const calculateMenuTotals = (reservationsArray) => {
    return reservationsArray.reduce(
      (totals, reservation) => ({
        menu5Steps: totals.menu5Steps + Number(reservation.menu5Steps || 0),
        menu3Steps: totals.menu3Steps + Number(reservation.menu3Steps || 0),
        menuInfantil:
          totals.menuInfantil + Number(reservation.menuInfantil || 0),
      }),
      {
        menu5Steps: 0,
        menu3Steps: 0,
        menuInfantil: 0,
      }
    );
  };

  const fetchReservations = async () => {
    if (!selectedDate.year || !selectedDate.month || !selectedDate.day) return;

    setLoading(true);
    try {
      const reservationsRef = ref(
        db,
        `restaurant/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}/reservations`
      );
      const snapshot = await get(reservationsRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        const reservationsArray = Object.entries(data)
          .map(([key, value]) => ({ ...value, id: key }))
          .filter((reservation) => reservation.pago); // Only get reservations with payment status

        setReservations(reservationsArray);
        setMenuTotals(calculateMenuTotals(reservationsArray));
      } else {
        setReservations([]);
        setMenuTotals({ menu5Steps: 0, menu3Steps: 0, menuInfantil: 0 });
      }
    } catch (err) {
      console.error("Error fetching reservations:", err);
      setError("Could not fetch reservations. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReservations();
  }, [selectedDate]);

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="mb-8">
        <input
          type="date"
          name="date"
          id="date"
          className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-full"
          required
          onChange={(e) => {
            const date = new Date(e.target.value);
            const offset = date.getTimezoneOffset() * 60 * 1000;
            const newDate = new Date(date.getTime() + offset);
            const year = newDate.getFullYear();
            const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
            const day = newDate.getDate().toString().padStart(2, "0");
            setSelectedDate({ year, month, day });
          }}
        />
      </div>

      <div className="bg-white rounded-lg shadow p-6 mb-8">
        <h2 className="text-xl font-bold mb-4">Menu Totals</h2>
        <div className="grid grid-cols-3 gap-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold">5 Steps Menu</h3>
            <p className="text-2xl font-bold">{menuTotals.menu5Steps}</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold">3 Steps Menu</h3>
            <p className="text-2xl font-bold">{menuTotals.menu3Steps}</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold">Children's Menu</h3>
            <p className="text-2xl font-bold">{menuTotals.menuInfantil}</p>
          </div>
        </div>
      </div>

      {loading && <p className="text-center">Loading...</p>}
      {error && <p className="text-red-500 text-center">{error}</p>}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {reservations.map((reservation) => (
          <Card key={reservation.id} data={reservation} />
        ))}
      </div>

      {!loading && reservations.length === 0 && (
        <p className="text-center text-gray-500">
          No paid reservations found for this date.
        </p>
      )}
    </div>
  );
};

export default PaidReservationsList;
