// Import the functions you need from the SDKs you need

import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrw0O9MdThjuZbQoP70WBoGzQyUBur56A",
  authDomain: "caelumresto-f977c.firebaseapp.com",
  projectId: "caelumresto-f977c",
  storageBucket: "caelumresto-f977c.appspot.com",
  messagingSenderId: "555479780939",
  appId: "1:555479780939:web:253fbcf7fd1cb9e1e407ce",
  measurementId: "G-T735WPJK5Q",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const auth = getAuth(app);

export { database, auth, app };
