import "./App.css";
import LoginPage from "./LoginPage";
import ManageReservation from "./ManageReservation";
import PaidReservationsList from "./PaidReservationsList";
import UnpaidReservationsList from "./UnpaidReservationsList";
import { AuthProvider } from "./auth/auth";
import Booking from "./booking2";
import BookingAgencia from "./bookingAgencia";
import NavBar from "./components/NavBar";
import ReservationPage from "./landing2";
import RestaurantMenu from "./menuResto";
import {
  ProtectedRoute,
  FullAdminRoute,
  EditAdminRoute,
} from "./routes/ProtectedRoute";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <AuthProvider>
        <div>
          <Routes>
            {/* Public routes without NavBar */}
            <Route path="/" element={<ReservationPage />} />
            <Route path="/menu" element={<RestaurantMenu />} />
            <Route path="/reservas" element={<Booking />} />
            <Route path="/reservasAgencia" element={<BookingAgencia />} />
            <Route path="/login" element={<LoginPage />} />

            {/* Protected routes with NavBar */}
            <Route
              path="/Pfslrc"
              element={
                <>
                  <NavBar />
                  <div className="container mx-auto">
                    <FullAdminRoute>
                      <UnpaidReservationsList />
                    </FullAdminRoute>
                  </div>
                </>
              }
            />
            <Route
              path="/LdCsTP"
              element={
                <>
                  <NavBar />
                  <div className="container mx-auto">
                    <FullAdminRoute>
                      <ManageReservation />
                    </FullAdminRoute>
                  </div>
                </>
              }
            />
            <Route
              path="/JEpcdlv"
              element={
                <>
                  <NavBar />
                  <div className="container mx-auto">
                    <FullAdminRoute>
                      <PaidReservationsList />
                    </FullAdminRoute>
                  </div>
                </>
              }
            />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
