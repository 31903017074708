// App.js

import AvailableSeats from "./AvailableSeats";
import MakeReservation from "./makeReservation";
import TimePicker from "./timePicker";
import React from "react";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

//TODO block all wednesdays because the restaurant is closed.

function App() {
  const { t, i18n } = useTranslation();
  const [customerName, setCustomerName] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [seatsReserved, setSeatsReserved] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [time, setTime] = useState("");
  const [foodRestrictions, setFoodRestrictions] = useState("");
  const [menu5Steps, setMenu5Steps] = useState("");
  const [menu3Steps, setMenu3Steps] = useState("");
  const [menuInfantil, setMenuInfantil] = useState("");
  const [confirmationNumber, setConfirmationNumber] = useState(null);
  const [sendMessage, setSendMessage] = useState(false);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const sendWhatsAppMessage = (
    customerName,
    seatsReserved,
    day,
    month,
    year,
    time,
    foodRestrictions,
    menu5Steps,
    menu3Steps,
    menuInfantil
  ) => {
    const message = `Hola, me llamo ${customerName} y quiero hacer una reserva para ${seatsReserved} personas el ${day}/${month}/${year} a las ${time}. Tengo las siguientes restricciones alimentarias: ${foodRestrictions}. Quiero los siguientes menus: 5pasos ${menu5Steps}, 3pasos ${menu3Steps} y Infantil ${menuInfantil}. Codigo de confirmación: ${confirmationNumber}. Gracias!`;
    window.open(
      `https://wa.me/5492615330110?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      <header className="flex justify-between items-center p-4">
        <img
          src="/LogoCaelumResto.jpg"
          alt="Caelum Restó"
          className="w-24 h-24"
        />
        <nav className="flex items-center ">
          <a href="/" className="px-4">
            {t("Home")}
          </a>
          <a href="/menu" className="px-4">
            {t("seeMenu")}
          </a>
          <button
            className="px-4"
            onClick={() => {
              const lang =
                i18n.language === "es"
                  ? "en"
                  : i18n.language === "en"
                  ? "pt"
                  : "es";
              handleLanguageChange(lang);
            }}
          >
            <img
              src={`${i18n.language}.svg`}
              alt={`${i18n.language} flag`}
              className="w-6 h-6"
            />
          </button>
        </nav>
      </header>
      <div className="w-full md:w-1/2 px-6 items-center mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-extrabold">{t("bookTable")}</h1>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const formElements = e.target.elements;
              const date = new Date(formElements.date.value);
              const offset = date.getTimezoneOffset() * 60 * 1000;
              const newDate = new Date(date.getTime() + offset);
              const year = newDate.getFullYear();
              const month = (newDate.getMonth() + 1)
                .toString()
                .padStart(2, "0");
              const day = newDate.getDate().toString().padStart(2, "0");
              const customerName = formElements.name.value;
              const contactInfo = formElements.contactInfo.value;
              const seatsReserved = formElements.people.value;
              const time = formElements.time.value;
              const foodRestrictions = formElements.foodRestrictions.value;
              const nameAgencia = "Web";
              const menu5Steps = formElements.menu5Steps.value;
              const menu3Steps = formElements.menu3Steps.value;
              const menuInfantil = formElements.menuInfantil.value;

              setCustomerName(customerName);
              setContactInfo(contactInfo);
              setSeatsReserved(seatsReserved);
              setDay(day);
              setMonth(month);
              setYear(year);
              setTime(time);
              setFoodRestrictions(foodRestrictions);
              setMenu5Steps(menu5Steps);
              setMenu3Steps(menu3Steps);
              setMenuInfantil(menuInfantil);

              // Create a new div for the reservation result if it doesn't exist
              let reservationResultDiv =
                document.getElementById("reservationResult");
              if (!reservationResultDiv) {
                reservationResultDiv = document.createElement("div");
                reservationResultDiv.id = "reservationResult";
                e.target.parentNode.appendChild(reservationResultDiv);
              }

              // Render the MakeReservation component and wait for the result
              const reservationComponent = (
                <MakeReservation
                  year={year}
                  month={month}
                  day={day}
                  customerName={customerName}
                  contactInfo={contactInfo}
                  seatsReserved={seatsReserved}
                  time={time}
                  foodRestrictions={foodRestrictions}
                  nameAgencia={nameAgencia}
                  menu5Steps={menu5Steps}
                  menu3Steps={menu3Steps}
                  menuInfantil={menuInfantil}
                  notes={
                    "Precio al momento de la reserva: 5 " +
                    t("monto5") +
                    " 3 " +
                    t("monto3")
                  }
                />
              );

              ReactDOM.render(reservationComponent, reservationResultDiv);

              // Wait for the success message to appear
              const checkForSuccess = setInterval(() => {
                const successMessage = reservationResultDiv.querySelector(
                  '[style*="color: green"]'
                );
                if (successMessage) {
                  clearInterval(checkForSuccess);
                  // Reset all form fields
                  e.target.reset();
                  // Clear the reservation display
                  const reservationDisplay =
                    document.getElementById("reservation");
                  if (reservationDisplay) {
                    reservationDisplay.innerHTML = "";
                  }
                  // Scroll to the success message
                  successMessage.scrollIntoView({ behavior: "smooth" });

                  // Get confirmationNumber from the success message
                  const confirmationNumber = successMessage.textContent;
                  const lastFiveCharacters = confirmationNumber.slice(-5);
                  console.log("lastFiveCharacters:" + lastFiveCharacters);
                  setConfirmationNumber(lastFiveCharacters);

                  // Send WhatsApp message
                  sendWhatsAppMessage(
                    customerName,
                    seatsReserved,
                    day,
                    month,
                    year,
                    time,
                    foodRestrictions,
                    menu5Steps,
                    menu3Steps,
                    menuInfantil,
                    confirmationNumber
                  );
                }
              }, 100);
            }}
          >
            <div className="flex flex-col">
              <label
                className="block text-sm font-bold text-left"
                htmlFor="name"
              >
                {t("nameComensal")}
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
                required
              />
            </div>
            <div className="flex flex-col mt-4">
              <label
                className="block text-sm font-bold text-left"
                htmlFor="contactInfo"
              >
                {t("contactInfo")}
              </label>
              <input
                type="text"
                name="contactInfo"
                id="contactInfo"
                placeholder={t("phoneOrEmail")}
                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
                required
                pattern="^(?:\+?[\d\s-]+|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)$"
                title={t("enterValidPhoneOrEmail")}
              />
            </div>
            <div className="flex flex-col w-full mt-4">
              <label
                className="block text-sm font-bold text-left"
                htmlFor="date"
              >
                {t("date")}
              </label>
              <input
                type="date"
                name="date"
                id="date"
                className="w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
                required
                onChange={(e) => {
                  const date = new Date(e.target.value);
                  const offset = date.getTimezoneOffset() * 60 * 1000;
                  const newDate = new Date(date.getTime() + offset);
                  const year = newDate.getFullYear();
                  const month = (newDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const day = newDate.getDate().toString().padStart(2, "0");
                  const dayOfWeek = newDate.getDay();
                  if (dayOfWeek === 3) {
                    alert(t("wedClosed"));
                    e.target.value = "";
                    return;
                  }
                  ReactDOM.render(
                    <AvailableSeats year={year} month={month} day={day} />,
                    document.getElementById("reservation")
                  );
                }}
              />
            </div>
            <div id="reservation"></div>
            <div className="flex flex-col mt-4">
              <label
                className="block text-sm font-bold text-left"
                htmlFor="people"
              >
                {t("numberOfPeople")}
              </label>
              <input
                type="number"
                name="people"
                id="people"
                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
                required
                min={1}
                max={37}
              />
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex flex-col w-1/3 mr-2">
                <label
                  className="block text-sm font-bold text-left"
                  htmlFor="menu5Steps"
                >
                  {t("menu5pasos")}
                </label>
                <input
                  type="number"
                  name="menu5Steps"
                  id="menu5Steps"
                  className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
                  required
                  min={0}
                  step={"1"}
                  placeholder="0"
                  max={37} //TODO add max number of people - other menu
                />
              </div>
              <div className="flex flex-col w-1/3">
                <label
                  className="block text-sm font-bold text-left"
                  htmlFor="menu3Steps"
                >
                  {t("menu3pasos")}
                </label>
                <input
                  type="number"
                  name="menu3Steps"
                  id="menu3Steps"
                  className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
                  required
                  step={"1"}
                  placeholder="0"
                  min={0}
                  max={37} //TODO add max number of people - other menu
                />
              </div>
              <div className="flex flex-col w-1/3 ml-2">
                <label
                  className="block text-sm font-bold text-left"
                  htmlFor="menuInfantil"
                >
                  {t("menuInfantil")}
                </label>
                <input
                  type="number"
                  name="menuInfantil"
                  id="menuInfantil"
                  className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
                  required
                  step={"1"}
                  placeholder="0"
                  min={0}
                  max={37} //TODO add max number of people - other menu
                />
              </div>
            </div>
            <div className="flex flex-col">
              <TimePicker />
              <div className="flex flex-col mt-4">
                <label
                  className="block text-sm font-bold text-left"
                  htmlFor="foodRestrictions"
                >
                  {t("foodRestrictions")}
                </label>
                <select
                  name="foodRestrictions"
                  id="foodRestrictions"
                  className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
                >
                  <option value="none">{t("none")}</option>
                  <option value="vegan">{t("vegan")}</option>
                  <option value="glutenFree">{t("glutenFree")}</option>
                  <option value="kosher">{t("kosher")}</option>
                  <option value="halal">{t("halal")}</option>
                  <option value="other">{t("other")}</option>
                </select>
              </div>
            </div>
            <div id="reservation"></div>
            <div className="flex">
              <button
                type="submit"
                className="mt-4 mx-2 w-full px-4 py-2 text-sm font-bold text-white bg-[#800000] hover:bg-[#002e60]/90 rounded-full"
              >
                {t("reserveViaWeb")}
              </button>
            </div>
          </form>
          <div id="reservationResult"></div>
          <div className="mt-8">
            <h2 className="text-xl font-bold text-center">
              {t("orContactUs")}
            </h2>
            <div className="flex mt-4">
              <a
                href={`https://wa.me/5492615330110?text=${encodeURIComponent(
                  t("helloIWantToMakeAReservation")
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 w-1/2 px-4 py-2 text-sm font-bold text-white text-center bg-[#34B853] hover:bg-[#34B853]/90 rounded-full"
              >
                {t("send")}
              </a>
              <a
                href="mailto:reservas@caelumresto.com"
                className="mx-2 w-1/2 px-4 py-2 text-sm font-bold text-white text-center bg-[#002e60] hover:bg-[#b2b3b5]/90 rounded-full"
              >
                {t("sendEmail")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
