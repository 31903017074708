import { auth } from "../firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth State Changed:", user); // Debug log

      if (user) {
        // Get the ID token to check custom claims
        user.getIdTokenResult().then((idTokenResult) => {
          console.log("Token Claims:", idTokenResult.claims); // Debug log

          user.isAdmin = idTokenResult.claims.admin || false;
          user.accessLevel = idTokenResult.claims.accessLevel;

          console.log("Updated User Object:", {
            email: user.email,
            isAdmin: user.isAdmin,
            accessLevel: user.accessLevel,
          }); // Debug log

          setUser(user);
        });
      } else {
        console.log("No user signed in"); // Debug log
        setUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("Login successful:", userCredential.user.email); // Debug log
      return userCredential;
    } catch (error) {
      console.error("Login error:", error); // Debug log
      throw error;
    }
  };

  const logout = () => {
    return signOut(auth);
  };

  // Debug log current auth state
  console.log("Current Auth State:", {
    user: user
      ? {
          email: user.email,
          isAdmin: user.isAdmin,
          accessLevel: user.accessLevel,
        }
      : null,
    loading,
  });

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
