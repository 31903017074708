// AvailableSeats.js
import React, { useState, useEffect } from "react";
import { database } from "./firebase"; // Adjust the path as necessary
import { ref, get, set } from "firebase/database";
import { useTranslation } from "react-i18next";

const AvailableSeats = ({ year, month, day }) => {
  const [availableSeats, setAvailableSeats] = useState(null);
  const [error, setError] = useState(null);

  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const fetchAvailableSeats = async () => {
      const dateRef = ref(database, `restaurant/${year}/${month}/${day}`);

      try {
        const snapshot = await get(dateRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setAvailableSeats(data.availableSeats);
        } else {
          // Initialize the node with 37 seats if it doesn't exist
          const initialData = {
            availableSeats: 37,
            reservations: {}, // Initialize empty reservations object
          };

          await set(dateRef, initialData);
          setAvailableSeats(37);
        }
      } catch (err) {
        console.error("Error fetching available seats:", err);
        setError("Could not fetch data. Please try again later.");
      }
    };

    fetchAvailableSeats();
  }, [year, month, day]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>
        {t("availableSeats")} <span></span>
        {availableSeats !== null ? availableSeats : "Loading..."}
      </h2>
    </div>
  );
};

export default AvailableSeats;
