import React from "react";
import { useTranslation } from "react-i18next";

const MenuSection = ({ title, items, vinos }) => (
  <div className="mb-6">
    <h2 className="text-xl font-semibold ml-2 mb-2 text-[#ffd7AA]">{title}</h2>
    {items.map((item, index) => (
      <div key={index} className="mb-2">
        <div className="flex justify-between text-[#DDDDDD] ml-4">
          <span className="font-medium">{item.name}</span>
          {item.price && <span className="">{item.price}</span>}
        </div>
        {item.description && <p className="text-sm">{item.description}</p>}
      </div>
    ))}
    {vinos && <p className="text-sm ml-4 text-[#ffd7AA]">Maridaje: {vinos}</p>}
  </div>
);

const RestaurantMenu = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const menuData = {
    menu3Pasos: {
      entradas: [
        { name: t("menu.entradas.item1") },
        { name: t("menu.entradas.item2") },
        { name: t("menu.entradas.item3") },
      ],
      principales: [
        { name: t("menu.principales.item1") },
        { name: t("menu.principales.item2") },
        { name: t("menu.principales.item3") },
      ],
      postre: [
        { name: t("menu.postre.item1") },
        { name: t("menu.postre.item2") },
      ],
      precio: { name: t("monto3"), price: t("") },
    },
    menu5Pasos: {
      primerEntrada: [{ name: t("menu.entradas.item1") }],
      segundaEntrada: [{ name: t("menu.entradas.item2") }],
      principales: [
        { name: t("menu.principales.item1") },
        { name: t("menu.principales.item2") },
        { name: t("menu.principales.item3") },
      ],
      prePostre: [{ name: t("menu.prePostre.item1") }],
      postre: [
        { name: t("menu.postre.item1") },
        { name: t("menu.postre.item2") },
      ],
      precio: { name: t("monto5"), price: t("") },
    },
  };

  return (
    <div className="bg-[#000000] text-white min-h-screen flex flex-col">
      <header className="flex justify-between items-center p-4">
        <img
          src="/LogoCaelumResto.jpg"
          alt="Caelum Restó"
          className="w-24 h-24"
        />
        <nav className="flex items-center ">
          <a href="/" className="px-4">
            {t("Home")}
          </a>
          <a href="/reservas" className="px-4">
            {t("bookTable")}
          </a>
          <button
            className="px-4"
            onClick={() => {
              const lang =
                i18n.language === "es"
                  ? "en"
                  : i18n.language === "en"
                  ? "pt"
                  : "es";
              handleLanguageChange(lang);
            }}
          >
            <img
              src={`${i18n.language}.svg`}
              alt={`${i18n.language} flag`}
              className="w-6 h-6"
            />
          </button>
        </nav>
      </header>
      <div className="flex justify-center items-center min-h-screen bg-black">
        <div
          className="w-full max-w-6xl text-white relative overflow-hidden"
          style={{
            backgroundImage: "url('/fondoMenu.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
          <div className="relative flex flex-col md:flex-row z-10">
            <div className="md:w-1/2 mt-24">
              <div className="p-8">
                <h2 className="text-2xl font-bold">{t("menu3pasos")}</h2>
                <MenuSection
                  title={t("starters")}
                  items={menuData.menu3Pasos.entradas}
                  vinos={t("vinos.cabernetTerrunio")}
                />
                <MenuSection
                  title={t("mains")}
                  items={menuData.menu3Pasos.principales}
                  vinos={t("vinos.malbecTerrunio")}
                />
                <MenuSection
                  title={t("desserts")}
                  items={menuData.menu3Pasos.postre}
                  vinos={t("vinos.fianoTerrunio")}
                />
                <MenuSection
                  title={t("precio")}
                  items={[menuData.menu3Pasos.precio]}
                />
              </div>
            </div>
            <div className="md:w-1/2 mt-24 z-10">
              <div className="p-8">
                <h2 className="text-2xl font-bold ">{t("menu5pasos")}</h2>
                <MenuSection
                  title={t("starters")}
                  items={menuData.menu5Pasos.primerEntrada}
                  vinos={t("vinos.cabernetTerrunio")}
                />
                <MenuSection
                  title={t("starters2")}
                  items={menuData.menu5Pasos.segundaEntrada}
                  vinos={t("vinos.cabernetTerrunio")}
                />
                <MenuSection
                  title={t("mains")}
                  items={menuData.menu5Pasos.principales}
                  vinos={t("vinos.malbecReserva")}
                />
                <MenuSection
                  title={t("predesserts")}
                  items={menuData.menu5Pasos.prePostre}
                />
                <MenuSection
                  title={t("desserts")}
                  items={menuData.menu5Pasos.postre}
                  vinos={t("vinos.fianoTerrunio")}
                />
                <MenuSection
                  title={t("Precio")}
                  items={[menuData.menu5Pasos.precio]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantMenu;
