// MakeReservation.js

import { database } from "./firebase";
// Adjust the path as necessary
import { ref, get, push, update } from "firebase/database";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const MakeReservation = ({
  year,
  month,
  day,
  customerName,
  contactInfo,
  seatsReserved,
  time,
  foodRestrictions,
  nameAgencia,
  menu5Steps,
  menu3Steps,
  menuInfantil,
  notes,
}) => {
  const { t, i18n } = useTranslation();
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const handleReservation = async () => {
    const datePath = `restaurant/${year}/${month}/${day}`;
    const dateRef = ref(database, datePath);

    try {
      // Fetch current seating data
      const snapshot = await get(dateRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const availableSeats = data.availableSeats;

        if (availableSeats >= seatsReserved) {
          // Create a reservation entry
          const reservationsRef = ref(database, `${datePath}/reservations`);
          const newReservationRef = push(reservationsRef);

          const newReservation = {
            customerName,
            contactInfo,
            seatsReserved,
            time,
            foodRestrictions,
            nameAgencia,
            menu5Steps,
            menu3Steps,
            menuInfantil,
            notes,
          };

          // Create updates object
          const updates = {};
          // Update the new reservation
          updates[`${datePath}/reservations/${newReservationRef.key}`] =
            newReservation;
          // Update only the availableSeats field
          updates[`${datePath}/availableSeats`] =
            availableSeats - seatsReserved;
          // Update index entry to search via confirmation code
          updates[`restaurant/index/${newReservationRef.key}`] =
            newReservation.customerName;
          // Update index entry to get reservation date
          updates[
            `restaurant/indexDate/${newReservationRef.key}`
          ] = `${year}/${month}/${day}`;

          // Update multiple paths atomically
          await update(ref(database), updates);

          // Success message
          setSuccessMessage(
            t("reservationMadeSuccessfully") +
              " " +
              newReservationRef.key.slice(-5)
          );
          setError(null);
        } else {
          setError(t("notEnoughAvailableSeats"));
        }
      } else {
        setError(t("noDataFoundForThisDate"));
      }
    } catch (err) {
      console.error("ErrorMakingReservation:", err);
      setError(t("couldNotMakeReservation"));
    }
  };

  useEffect(() => {
    handleReservation();
  }, []);

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
    </div>
  );
};

export default MakeReservation;
