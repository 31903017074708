import CustomCard from "./customCard";
import { database } from "./firebase";
import {
  ref,
  get,
  query,
  orderByValue,
  equalTo,
  update,
  remove,
} from "firebase/database";
import React, { useState, useEffect } from "react";

const db = database;

const ManageReservation = () => {
  const [searchType, setSearchType] = useState("confirmationCode");
  const [searchInput, setSearchInput] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [reservation, setReservation] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    year: "",
    month: "",
    day: "",
  });
  const [paymentStatus, setPaymentStatus] = useState("");
  const [newNote, setNewNote] = useState("");
  const [statusMessage, setStatusMessage] = useState({ type: "", message: "" });

  // Search by last 5 characters of reservation ID
  const searchByReservationIdEnding = async (reservationEnding) => {
    const indexRef = ref(db, "restaurant/index");
    const snapshot = await get(indexRef);
    if (snapshot.exists()) {
      const allReservations = snapshot.val();
      const result = Object.keys(allReservations).filter((id) =>
        id.endsWith(reservationEnding)
      );
      return result.map((id) => ({ id, name: allReservations[id] }));
    }
    return [];
  };

  // Search by customer name
  const searchByCustomerName = async (name) => {
    const indexRef = ref(db, "restaurant/index");
    const nameQuery = query(indexRef, orderByValue(), equalTo(name));
    const snapshot = await get(nameQuery);
    if (snapshot.exists()) {
      const result = [];
      snapshot.forEach((childSnapshot) => {
        result.push({ id: childSnapshot.key, name: childSnapshot.val() });
      });
      return result;
    }
    return [];
  };

  // Get reservation date
  const getReservationDate = async (reservationId) => {
    const indexRef = ref(db, "restaurant/indexDate");
    const snapshot = await get(indexRef);
    if (snapshot.exists() && snapshot.val()[reservationId]) {
      const [year, month, day] = snapshot.val()[reservationId].split("/");
      return { year, month, day };
    }
    return null;
  };

  const handleSearch = async () => {
    try {
      setStatusMessage({ type: "", message: "" });
      const results =
        searchType === "confirmationCode"
          ? await searchByReservationIdEnding(searchInput)
          : await searchByCustomerName(searchInput);

      if (results.length === 0) {
        setStatusMessage({ type: "error", message: "No reservation found" });
        return;
      }

      setReservationId(results[0].id);
      const date = await getReservationDate(results[0].id);
      if (date) {
        setSelectedDate(date);
      }
    } catch (error) {
      setStatusMessage({
        type: "error",
        message: "Error searching for reservation",
      });
    }
  };

  // Fetch reservation details
  useEffect(() => {
    const fetchReservation = async () => {
      if (!reservationId || !selectedDate.year) return;

      try {
        const reservationRef = ref(
          db,
          `restaurant/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}/reservations/${reservationId}`
        );
        const snapshot = await get(reservationRef);
        if (snapshot.exists()) {
          setReservation({ id: reservationId, ...snapshot.val() });
        }
      } catch (error) {
        setStatusMessage({
          type: "error",
          message: "Error fetching reservation details",
        });
      }
    };

    fetchReservation();
  }, [reservationId, selectedDate]);

  // Update payment status
  const updatePaymentStatus = async () => {
    try {
      const reservationRef = ref(
        db,
        `restaurant/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}/reservations/${reservationId}`
      );
      await update(reservationRef, { pago: paymentStatus });
      setStatusMessage({
        type: "success",
        message: "Payment status updated successfully",
      });
      setReservation((prev) => ({ ...prev, pago: paymentStatus }));
    } catch (error) {
      setStatusMessage({
        type: "error",
        message: "Error updating payment status",
      });
    }
  };

  // Append new note
  const appendNote = async () => {
    if (!newNote.trim()) return;

    try {
      const currentNotes = reservation.notes || "";
      const updatedNotes = currentNotes
        ? `${currentNotes}\n${newNote}`
        : newNote;
      const reservationRef = ref(
        db,
        `restaurant/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}/reservations/${reservationId}`
      );
      await update(reservationRef, { notes: updatedNotes });
      setStatusMessage({ type: "success", message: "Note added successfully" });
      setReservation((prev) => ({ ...prev, notes: updatedNotes }));
      setNewNote("");
    } catch (error) {
      setStatusMessage({ type: "error", message: "Error adding note" });
    }
  };

  // Delete reservation
  const deleteReservation = async () => {
    if (!window.confirm("Are you sure you want to delete this reservation?"))
      return;

    try {
      // Remove from main reservation location
      const reservationRef = ref(
        db,
        `restaurant/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}/reservations/${reservationId}`
      );
      await remove(reservationRef);

      // Remove from indexes
      const indexRef = ref(db, `restaurant/index/${reservationId}`);
      const dateIndexRef = ref(db, `restaurant/indexDate/${reservationId}`);
      await remove(indexRef);
      await remove(dateIndexRef);

      // Update available seats
      const dateRef = ref(
        db,
        `restaurant/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}/availableSeats`
      );
      const seatsSnapshot = await get(dateRef);
      if (seatsSnapshot.exists()) {
        const currentSeats = seatsSnapshot.val();
        await update(
          ref(
            db,
            `restaurant/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`
          ),
          {
            availableSeats: currentSeats + parseInt(reservation.seatsReserved),
          }
        );
      }

      setStatusMessage({
        type: "success",
        message: "Reservation deleted successfully",
      });
      setReservation(null);
      setReservationId("");
      setSearchInput("");
    } catch (error) {
      setStatusMessage({
        type: "error",
        message: "Error deleting reservation",
      });
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      {/* Search Section */}
      <div className="space-y-4">
        <div className="flex items-center">
          <label htmlFor="confirmationCode" className="block mr-2">
            Confirmation Code:
          </label>
          <input
            type="text"
            name="confirmationCode"
            id="confirmationCode"
            className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 mr-2"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              setSearchType("confirmationCode");
            }}
          />
          <label htmlFor="customerName" className="block mr-2">
            Customer Name:
          </label>
          <input
            type="text"
            name="customerName"
            id="customerName"
            className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
              setSearchType("customerName");
            }}
          />
        </div>
        <button
          className="mt-4 px-4 py-2 bg-[#800000] hover:bg-blue-700 text-white font-bold rounded-full"
          onClick={handleSearch}
        >
          Search Reservation
        </button>
      </div>

      {statusMessage.message && (
        <div
          className={`p-4 rounded ${
            statusMessage.type === "error"
              ? "bg-red-100 text-red-700"
              : "bg-green-100 text-green-700"
          }`}
        >
          {statusMessage.message}
        </div>
      )}

      {reservation && (
        <div className="space-y-6">
          <CustomCard data={reservation} />

          {/* Management Controls */}
          <div className="space-y-4 mt-4">
            {/* Payment Status Update */}
            <div className="mt-4">
              <label htmlFor="paymentStatus" className="block mb-2">
                Update Payment Status:
              </label>
              <select
                name="paymentStatus"
                id="paymentStatus"
                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md"
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <option value="">Select status</option>
                <option value="no">No Pago</option>
                <option value="adelanto">Adelanto</option>
                <option value="full">Pago</option>
              </select>
              <button
                className="mt-4 px-4 py-2 bg-[#800000] hover:bg-blue-700 text-white font-bold rounded-full"
                onClick={updatePaymentStatus}
              >
                Update Payment
              </button>
            </div>

            {/* Notes Section */}
            <div className="mt-4">
              <label htmlFor="notes" className="block mb-2">
                Add Note:
              </label>
              <textarea
                name="notes"
                id="notes"
                className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Add a new note..."
                rows={3}
              />
              <button
                className="mt-4 px-4 py-2 bg-[#800000] hover:bg-blue-700 text-white font-bold rounded-full"
                onClick={appendNote}
              >
                Add Note
              </button>
            </div>

            {/* Delete Button */}
            <button
              className="mt-4 px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-bold rounded-full"
              onClick={deleteReservation}
            >
              Delete Reservation
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageReservation;
