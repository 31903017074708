// Card.js
import React from "react";

const Card = ({ data }) => {
  return (
    <div className="card bg-eggshell m-2">
      <h2 className="customer-name my-2">{data.customerName}</h2>
      <p className="seats-reserved my-2">Comensales: {data.seatsReserved}</p>
      <p className="menu5steps my-2">Menu 5 pasos: {data.menu5Steps}</p>
      <p className="menu3steps">Menu 3 pasos:{data.menu3Steps}</p>
      <p
        className={
          data.foodRestrictions === "none"
            ? "food-restrictions my-2"
            : "food-restrictions bg-red-500 text-white my-2"
        }
      >
        Restricciones: {data.foodRestrictions}
      </p>
      <p className="nameAgencia my-2">Agencia: {data.nameAgencia}</p>
      <p className="phone my-2">Tel: {data.phone}</p>
      <p className="time my-2">Hora: {data.time}</p>
      <p className="notes my-2">Notas: {data.notes}</p>
      <p
        className={
          data.pago === "no"
            ? "payment my-2 bg-red-500"
            : data.pago === "adelanto"
            ? "payment my-2 bg-yellow-500"
            : data.pago === "full"
            ? "payment my-2 bg-green-500"
            : "payment my-2"
        }
      >
        Pago: {data.pago}
      </p>
      <p className="bookingRef my-2">
        Referencia:{" "}
        {data.id && typeof data.id === "string" ? data.id.slice(-5) : data.id}
      </p>
    </div>
  );
};

export default Card;
