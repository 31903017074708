import { useAuth } from "../auth/auth";
import { Navigate } from "react-router-dom";

// Base Protected Route for any admin
export function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || !user.isAdmin) {
    return <Navigate to="/login" />;
  }

  return children;
}

// Route for full admin access
export function FullAdminRoute({ children }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (
    !user ||
    !user.isAdmin ||
    (user.accessLevel !== "full" && user.accessLevel !== "edit")
  ) {
    return <Navigate to="/login" />;
  }

  return children;
}

// Route for edit-only access
export function EditAdminRoute({ children }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || !user.isAdmin || user.accessLevel !== "edit") {
    return <Navigate to="/login" />;
  }

  return children;
}
