import { useTranslation } from "react-i18next";

const TimePicker = () => {
  const { t } = useTranslation();

  // Generate time options in 15-minute intervals between 12:00 and 16:00
  const generateTimeOptions = () => {
    const times = [];
    const start = new Date();
    start.setHours(12, 0, 0);
    const end = new Date();
    end.setHours(16, 0, 0);

    while (start <= end) {
      times.push(
        start.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      );
      start.setMinutes(start.getMinutes() + 15);
    }
    return times;
  };

  return (
    <div className="flex flex-col mt-4">
      <label className="block text-sm font-bold text-left" htmlFor="time">
        {t("time")}
      </label>
      <select
        name="time"
        id="time"
        className="block w-full px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300"
        required
      >
        {generateTimeOptions().map((time) => (
          <option key={time} value={time}>
            {time}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimePicker;
